import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD7t_iv1Um2pLAZ6RRVV_yDhE9qc5EtC1M",
  authDomain: "box-blitz-cd126.firebaseapp.com",
  projectId: "box-blitz-cd126",
  storageBucket: "box-blitz-cd126.appspot.com",
  messagingSenderId: "671452943009",
  appId: "1:671452943009:web:92f4960a98ed0cf1ae96e0",
  measurementId: "G-81SD77J2C8",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const firestore = firebase.firestore();
